@use './mixins' as m;

.loader{
  @include m.center;
  width: 200px;
  background-color: transparent;
  border-radius: 10px;

  img{
    width: 100%;
    height: 90%;
    border-radius: 10px;
  }

  span{
    display: block;
    color: white;
    text-align: center;
    width: 100%;
    height: 10%;
    padding: 6px;
    font-family: "Segoe UI" , sans-serif;
    font-size: 1rem;
    font-weight: bold;
  }
}


